<script setup>
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { useForm } from '@inertiajs/vue3';

// Components
import AddressForm from '@/Components/Address/AddInForm.vue';
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
import Form from '@/Components/Location/Form.vue';

// Icons
import { mapErrors } from '@/utilities';
import saveIcon from '@i/save-white.svg';
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    company: Object,
    claOptions: Object,
});

const form = useForm({
    name: '',
    phone_number: '',
    administration_email: '',
    collective_labour_agreement_id: '',
    cost_center: '',
    deviate_from_collective_labour_agreement: false,
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
});

const sendForm = () => {
    form.post(route('staff.companies.locations.store', { company: props.company.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <StaffLayout title="Flexable">
        <FormSingleSettingBar :title="$t('Contact Details')">
            <FormKit type="form" id="location-update-form" @submit="sendForm" :actions="false">
                <div class="grid gap-x-8 md:grid-cols-2"><Form :form="form" :claOptions="claOptions" /></div>
            </FormKit>
        </FormSingleSettingBar>
        <FormSingleSettingBar :title="$t('Address')">
            <AddressForm :parentForm="form" />
        </FormSingleSettingBar>
        <div class="flex items-center justify-end w-full">
            <div class="">
                <Button
                    type="button"
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    :text="$t('Save')"
                    :icon="saveIcon"
                    @click="sendForm"
                />
            </div>
        </div>
    </StaffLayout>
</template>
